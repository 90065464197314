import React, {useState, useEffect} from 'react'
import ExportExcel from './ExportExcel'
import { MDBDataTable } from 'mdbreact';
import Directores from './Directores';
import {privateGET} from '../../services/Services';
const {REACT_APP_ENDPOINT, REACT_APP_FILES_URL} = process.env;

const data={
    columns:[
        {
    label: 'Fecha',
    field: 'date',
    sort: 'asc',
    width: 150
},
{
    label: 'Nombre',
    field: 'userName',
    sort: 'asc',
    width: 150
},
{
    label: 'Apellido',
    field: 'userLastName',
    sort: 'asc',
    width: 150
},
{
    label: 'Mail',
    field: 'email',
    sort: 'asc',
    width: 150
},
{
    label: 'Teléfono',
    field: 'phone',
    sort: 'asc',
    width: 150
},
{
    label: 'CUIT',
    field: 'cuit',
    sort: 'asc',
    width: 150
},
{
    label: 'Contrato',
    field: 'contract',
    sort: 'asc',
    width: 150
},
{
    label: 'Ingresos',
    field: 'paycheck',
    sort: 'asc',
    width: 150
},
{
    label: 'Alquiler',
    field: 'rent',
    sort: 'asc',
    width: 150
},
{
    label: 'Expensas',
    field: 'expenses',
    sort: 'asc',
    width: 150
},
{
    label: 'Servicios',
    field: 'services',
    sort: 'asc',
    width: 150
},
{
    label: 'Depósito',
    field: 'deposit',
    sort: 'asc',
    width: 150
},
{
    label: 'Duración',
    field: 'period',
    sort: 'asc',
    width: 150
},
{
    label: 'Moneda',
    field: 'currency',
    sort: 'asc',
    width: 150
}
]
}

const Cotizadores = () => {
    const fileName= 'Cotizaciones Alquiler'
    const [selectCategorie, setSelectCategorie]=useState(true)
    const handleClick=()=>{
        setSelectCategorie(!selectCategorie)
    }
    const [rentData, setRentData] = useState({
        columns: data.columns,
        rows: null
      });
      useEffect(()=> {
        privateGET(`${REACT_APP_ENDPOINT}public-api/rent`).then(r => {
          const newData = r.data.map(d => {
            return {
                id: d.id,
                date: d.createdAt.slice(0, -14),
                userName: d.name,
                userLastName: d.last_name,
                email: d.email,
                phone: d.phone,
                cuit: d.cuit,
                contract: <a href={REACT_APP_FILES_URL+d?.contract_file} download>{d?.contract_file}</a>,
                paycheck: <a href={REACT_APP_FILES_URL+d?.paycheck_file} download>{d?.paycheck_file}</a>,
                rent: d.rent,
                expenses: d.expenses,
                services: d.services,
                deposit: d.deposit,
                period: d.period,
                currency: d.currency
            }
            
          }).reverse();
          setRentData({...data, rows: newData});
        })
      },[])
    
  return (
    <div className='table-box'>
        <div className='select-cotizadores'>
        <h5 className={selectCategorie===true ? "categorie-selected": "categorie"} onClick={handleClick}>ALQUILER</h5>
        <h5 className={selectCategorie===false ? "categorie-selected": "categorie"}  onClick={handleClick}>DIRECTORES</h5>
        </div>
        {selectCategorie=== true? (
        <>
        <MDBDataTable
        striped
        bordered
        hover
        data={rentData} 
        />
        <ExportExcel apiData={rentData.rows} fileName={fileName}/>
        
    </>
    ):(
        <Directores />
    )}
    </div>
  )
}

export default Cotizadores